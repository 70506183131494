/* eslint-disable */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Swal from "sweetalert2";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Card, Grid, Icon, Paper } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import BottomMenus from "layouts/dashboards/BottomNavigation/bottom-menus";
import DepositForm from "layouts/deposit/deposit-form";
import { UpdateDeposit } from "api/deposit";
import appbarlogo from "assets/png/appbarlogo.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DepositHistory from "./deposit-history";
import HistoryTransactionIcon from "assets/png/history_transaction.png";

function Deposit() {
  const [deposit, setDeposit] = useState();
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);
  const [isShowHistory, setShowHistory] = useState(false);

  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;

  async function CancelDeposit() {
    const payload = {
      deposit_uid: deposit?.deposit_uid,
      status_code: "cancel",
    };
    try {
      const res = await UpdateDeposit(payload);
      console.log("CancelDeposit-res", res);
      history.push(`/`);
    } catch (e) {
      history.push(`/`);
    }
  }

  function handleBack() {
    if (deposit?.deposit_uid) {
      const newSwal = Swal.mixin({
        customClass: {
          confirmButton: "button button-info",
          cancelButton: "button button-error",
        },
      });
      newSwal.fire({
        title: "ยกเลิกรายการ",
        text: `ต้องการยกเลิกรายการฝากจำนวน ${deposit?.amount}`,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
        showCancelButton: true,
        allowOutsideClick: false,
        focusConfirm: false,
        preConfirm: () => {
          CancelDeposit();
        },
      });
    } else {
      history.push(`/`);
    }
  }

  useEffect(() => {
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
    // checkWaiting();
    // getPendingDeposit()
  }, []);

  console.log("deposit-deposit", deposit);

  return (
    <DashboardLayout height="100vh">
      <SuiBox display="flex" height="100vh">
        <Grid container spacing={3}>
          {!mobileView && <Grid item xs={12} lg={4} />}
          <Grid item xs={12} lg={4}>
            <SuiBox display="flex">
              <Card
                style={{
                  flex: 1,
                  backgroundColor: "#0000002e",
                  height: "100vh",
                  // marginTop: "30px",
                  // marginBottom: "30px",
                }}
              >
                <AppBar
                  position="sticky"
                  color="inherit"
                  style={{ backgroundColor: "transparent" }}
                >
                  <SuiBox display="flex" width="100%" height="60px">
                    <SuiBox
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleBack()}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      px={2}
                    >
                      <Icon className="text-white" color="inherit">
                        arrow_back_ios
                      </Icon>
                    </SuiBox>
                    <SuiBox display="flex" flex={1} justifyContent="center" alignItems="center">
                      <SuiTypography variant="h5" fontWeight="medium" textColor="white">
                        ฝากเงิน
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      sx={{ cursor: "pointer" }}
                      onClick={() => setShowHistory(true)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      px={2}
                    >
                      <SuiBox
                        component="img"
                        src={HistoryTransactionIcon}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="1.5rem"
                        height="1.5rem"
                      />
                    </SuiBox>
                  </SuiBox>
                </AppBar>
                <SuiBox display="flex" flexDirection="column" p={3}>
                  {user?.member_uid && (
                    <SuiBox display="flex" flexDirection="column">
                      <DepositForm onSetDeposit={(d) => setDeposit(d)} />
                    </SuiBox>
                  )}
                  {isShowHistory && <DepositHistory onClose={() => setShowHistory(false)} />}
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
          {!mobileView && <Grid item xs={12} lg={4} />}
        </Grid>
      </SuiBox>
      {user?.token && <BottomMenus value={0} />}
    </DashboardLayout>
  );
}

export default Deposit;
