import PageLayout from "examples/LayoutContainers/PageLayout";
import curved6 from "assets/images/curved-images/curved6.jpg";
import RegisterPage from "./register-page";

function Basic() {
  return (
    <PageLayout image={curved6}>
      <RegisterPage />
    </PageLayout>
  );
}

export default Basic;
