// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, functions }) => {
  const { gradients } = palette;
  const { linearGradient, rgba, pxToRem } = functions;

  return {
    dashboardLayout: {
      overflowX: "hidden",
      backgroundImage: ({ image }) =>
        image &&
        `${linearGradient(
          rgba(gradients.bgweb.main, 0.6),
          rgba(gradients.bgweb.state, 0.6)
        )}, url(${image})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      width: "calc(100% - 2rem)",
      height: `calc(100vh)`,
      margin: `0 ${pxToRem(16)} ${pxToRem(16)}`,
      padding: `${pxToRem(16)} 0 ${pxToRem(224)}`,
    },
  };
});
