/* eslint-disable no-console */
import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

function ProtectedRoute({ ...rest }) {
  const history = useHistory();
  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-info",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    if (!user || !user.token || user.token === "") {
      newSwal
        .fire({
          title: "กรุณาล็อกอิน",
          text: "",
          confirmButtonText: "ล็อกอิน",
          showCancelButton: false,
          allowOutsideClick: false,
          focusConfirm: false,
        })
        .then(() => history.push(`/sign-in`));
    }
  };

  useEffect(() => {
    showAlert();
  }, []);

  return <Route {...rest} />;
}

export default ProtectedRoute;
