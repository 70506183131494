import axios from "./index";

const base = "deposit";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

// export const DepositList = (data) => axios.post(`${base}/depositlist`, data);
// export const DepositConfirm = (data) => axios.post(`${base}/confirm`, data);

export const NewDeposit = async (data) => {
  try {
    const response = await axios.post(`${base}/newdeposit`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const UpdateDeposit = async (data) => {
  try {
    const response = await axios.post(`${base}/update`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const DepositBanks = async (data) => {
  try {
    const response = await axios.post(`${base}/banks`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const DepositList = async (data) => {
  try {
    const response = await axios.post(`${base}/depositlist`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const DepositConfirm = async (data) => {
  try {
    const response = await axios.post(`${base}/confirm`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const WaitingDeposit = async (data) => {
  try {
    const response = await axios.post(`${base}/waiting`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
