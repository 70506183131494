import { makeStyles } from "@mui/styles";

// eslint-disable-next-line
export default makeStyles(({ palette, functions, breakpoints, transitions }) => {
  // eslint-disable-next-line
  const { gradients } = palette;
  // eslint-disable-next-line
  const { linearGradient, rgba, pxToRem } = functions;

  return {
    layoutContainer: {
      position: "relative",
      // backgroundImage: () =>
      //   `${linearGradient(
      //     rgba(gradients.dark.main, 1),
      //     rgba(gradients.dark.main, 0.6)
      //   )}, url(${bg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      // width: "100vw",
      height: "100vh",

      [breakpoints.up("xl")]: {
        height: "100vh",
      },

      // [breakpoints.up("xl")]: {
      //   marginLeft: ({ direction, miniSidenav }) => {
      //     if (direction === "ltr") {
      //       return miniSidenav ? pxToRem(120) : pxToRem(274);
      //     }

      //     return false;
      //   },
      //   marginRight: ({ direction, miniSidenav }) => {
      //     if (direction === "rtl") {
      //       return miniSidenav ? pxToRem(120) : pxToRem(274);
      //     }

      //     return false;
      //   },
      //   transition: transitions.create(["margin-left", "margin-right"], {
      //     easing: transitions.easing.easeInOut,
      //     duration: transitions.duration.standard,
      //   }),
      // },
    },
  };
});
