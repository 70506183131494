import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import { Divider } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { useAuth } from "auth-context/auth.context";

function MemberProfile({ onChangePassword }) {
  const user = localStorage.getItem("user");
  const member = JSON.parse(user);
  const { logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    await logout();
    // window.location.reload();
    history.push(`/sign-in`);
  }

  return (
    <SuiBox px={3} alignItems="center">
      <SuiBox mt={1} display="flex" justifyContent="center">
        <SuiTypography component="label" variant="caption" textColor="secondary" flex={1}>
          ยูสเซอร์ไอดี:{" "}
          <SuiTypography component="label" variant="body2" flex={2}>
            {member?.username.replace(/\D/g, "")}
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
      <Divider />
      <SuiBox mt={1} display="flex" justifyContent="center">
        <SuiTypography component="label" variant="caption" textColor="secondary" flex={1}>
          ชื่อ-สกุล:{" "}
          <SuiTypography component="label" variant="body2" flex={2}>
            {member?.first_name} {member?.last_name}
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
      <Divider />
      <SuiBox mt={1} display="flex" justifyContent="center">
        <SuiTypography component="label" variant="caption" textColor="secondary" flex={1}>
          เบอร์โทร:{" "}
          <SuiTypography component="label" variant="body2" flex={2}>
            {member?.phone_number}
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
      <Divider />
      <SuiBox mt={1} display="flex" justifyContent="center">
        <SuiTypography component="label" variant="caption" textColor="secondary" flex={1}>
          ธนาคาร:{" "}
          <SuiTypography component="label" variant="body2" flex={2}>
            {member?.bank_name}
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
      <Divider />
      <SuiBox mt={1} display="flex" justifyContent="center">
        <SuiTypography component="label" variant="caption" textColor="secondary" flex={1}>
          เลขที่บัญชี:{" "}
          <SuiTypography component="label" variant="body2" flex={2}>
            {member?.member_bank_number}
          </SuiTypography>
        </SuiTypography>
      </SuiBox>
      <Divider />
      <SuiBox display="flex" justifyContent="flex-end" flexDirection="column">
        <SuiBox display="flex" justifyContent="flex-end">
          <SuiButton
            variant="outlined"
            size="small"
            buttonColor="dark"
            circular
            onClick={() => onChangePassword()}
          >
            เปลี่ยนรหัสผ่าน
          </SuiButton>
        </SuiBox>
        <Divider />
        <SuiBox display="flex" justifyContent="flex-end">
          <SuiButton
            variant="outlined"
            size="small"
            buttonColor="dark"
            circular
            onClick={() => handleLogout()}
          >
            ออกจากระบบ
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

MemberProfile.defaultProps = {
  onChangePassword: () => {},
};

// Typechecking props of the SuiAlert
MemberProfile.propTypes = {
  onChangePassword: PropTypes.func,
};

export default MemberProfile;
