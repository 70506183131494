import { useState } from "react";
import PropTypes from "prop-types";
import { Card, Dialog, DialogContent, Icon } from "@mui/material";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import MemberProfile from "./member-profile";
import ChangePassword from "./change-password";

function MemberProfilePopup({ onClose }) {
  const [activeStep, setActiveStep] = useState(0);
  const isOpen = true;
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <MemberProfile onChangePassword={() => setActiveStep(activeStep + 1)} />;
      case 1:
        return <ChangePassword onCancel={() => setActiveStep(activeStep - 1)} />;
      default:
        return null;
    }
  }
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0)",
          boxShadow: "none",
        },
      }}
      fullWidth
      open={isOpen}
    >
      <Card>
        <SuiBox
          p={2}
          display="flex"
          // backgroundColor="dark"
          backgroundGradient
          alignItems="center"
          justifyContent="space-between"
        >
          {activeStep === 0 && (
            <SuiTypography component="label" variant="h5" textColor="dark">
              ข้อมูลสมาชิก
            </SuiTypography>
          )}

          {activeStep === 1 && (
            <SuiTypography component="label" variant="h5" textColor="dark">
              เปลี่ยนรหัสผ่าน
            </SuiTypography>
          )}

          <SuiButton
            // variant="outlined"
            buttonColor="dark"
            size="small"
            circular
            iconOnly
            onClick={() => onClose()}
          >
            <Icon className="" color="inherit">
              close
            </Icon>
          </SuiButton>
        </SuiBox>
        <SuiBox>
          <DialogContent>{getStepContent(activeStep)}</DialogContent>
        </SuiBox>
      </Card>
    </Dialog>
  );
}

MemberProfilePopup.defaultProps = {
  onClose: () => {},
};

MemberProfilePopup.propTypes = {
  onClose: PropTypes.func,
};

export default MemberProfilePopup;
