/* eslint-disable */
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import { Card, Grid, Icon } from "@mui/material";
import Swal from "sweetalert2";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BottomMenus from "layouts/dashboards/BottomNavigation/bottom-menus";
import breakpoints from "assets/theme/base/breakpoints";
import appbarlogo from "assets/png/appbarlogo.png";
import { GETPROMOTIONLIST } from "api/util";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import styles from "./styles";
import "../../../fonts/DB_Helvethaica_X.ttf";

const API_SERVER =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_URL_AGENT
    : "http://localhost:8080/";

function PromotionList() {
  const classes = styles();
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);
  const [promotions, setPromotions] = useState([]);

  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;

  const getpromotionlist = async () => {
    Swal.fire({
      title: "Loading ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await GETPROMOTIONLIST();
      Swal.close();
      if (response?.data?.status === "success") {
        const data = response?.data?.data;
        console.log("getpromotionlist", data, response);
        setPromotions(data);
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      Swal.close();
      ResponseAlert(err?.response);
    }
  };

  useEffect(() => {
    console.log("useEffect");
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
    getpromotionlist();
  }, []);

  return (
    <SuiBox>
      <SuiBox py={1} mb={1}>
        <SuiTypography variant="h2" textColor="light" textGradient>
          โปรโมชั่นสำหรับสมาชิก
        </SuiTypography>
      </SuiBox>
      {promotions &&
        promotions.length > 0 &&
        promotions.map((p) => {
          console.log("PromotionList - > p", p);
          if (p?.promotion_img) {
            return (
              <SuiBox>
                <SuiBox
                  component="img"
                  src={`${API_SERVER}images/promotion/${p?.promotion_img}.jpg`}
                  alt={p?.promotion_name}
                  borderRadius="lg"
                />
              </SuiBox>
            );
          } else {
            return (
              <SuiBox py={1} mb={1}>
                <Card className={classes.betflixPlayCard}>
                  <SuiBox p={2} mt={1}>
                    <SuiBox display="flex" alignItems="center" justifyContent="space-between">
                      <Grid container>
                        <Grid item xs={8}>
                          <SuiBox display="flex" mt={6} justifyContent="center">
                            <SuiTypography variant="h3" textColor="white">
                              {p?.promotion_name}
                            </SuiTypography>
                          </SuiBox>
                        </Grid>
                        <Grid item xs={4}>
                          <SuiBox display="flex" mt={6} justifyContent="center">
                            <SuiTypography variant="h1" textColor="primary" textGradient>
                              {p?.value}
                              {p?.value_type === "percent" ? "%" : "เครดิต"}
                            </SuiTypography>
                          </SuiBox>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </SuiBox>
            );
          }
        })}
    </SuiBox>
  );
}

export default PromotionList;
