import axios from "./index";

const base = "verify";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

export const getOTP = async (data) => {
  try {
    const response = await axios.post(`${base}/getOTP`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const checkOTP = async (data) => {
  try {
    const response = await axios.post(`${base}/checkOTP`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await axios.post(`${base}/reset_password`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
