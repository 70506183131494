/* eslint-disable */
import PropTypes from "prop-types";
import { Card, CircularProgress, Dialog, DialogContent, Divider, Icon } from "@mui/material";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import { useEffect, useState } from "react";
import { friendReferral } from "api/member";
import { ResponseAlert } from "components/ResponseAlert";
// import {
//   FacebookMessengerShareButton,
//   FacebookMessengerIcon,
//   EmailShareButton,
//   EmailIcon,
//   FacebookShareButton,
//   FacebookIcon,
//   LineShareButton,
//   LineIcon,
//   TelegramShareButton,
//   TelegramIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   WhatsappShareButton,
//   WhatsappIcon,
// } from "react-share";

function FriendReferralPopup({ onClose }) {
  const [friend, setFriend] = useState("");
  const [link, setLink] = useState("");
  const [isLoading, setLoading] = useState(false);
  const isOpen = true;
  // const isRound = true;
  const user = localStorage.getItem("user");
  const member = JSON.parse(user);
  console.log("member", member);

  async function getFriendRef() {
    setLoading(true);
    try {
      const response = await friendReferral();
      console.log("getFriendRef-response", response);
      setLoading(false);
      if (response?.data?.status === "success") {
        const data = response?.data?.data;

        setFriend(data?.total_friend);
        const registerLink = `${window.location.origin}/sign-up?ref=${data?.code}`;
        console.log("getFriendRef-registerLink", registerLink);
        setLink(registerLink);
      }
    } catch (err) {
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  function handleCopyFriendRefLink() {
    navigator.clipboard.writeText(`${link}`);
  }

  useEffect(() => {
    getFriendRef();
  }, []);

  console.log("FriendReferralPopup-link", link);

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0)",
          boxShadow: "none",
        },
      }}
      fullWidth
      open={isOpen}
    >
      <Card>
        <SuiBox
          p={2}
          display="flex"
          // backgroundColor="dark"
          backgroundGradient
          alignItems="center"
          justifyContent="space-between"
        >
          <SuiTypography component="label" variant="h5" textColor="dark">
            แนะนำเพื่อน
          </SuiTypography>
          <SuiButton
            // variant="outlined"
            buttonColor="dark"
            size="small"
            circular
            iconOnly
            onClick={() => onClose()}
          >
            <Icon className="" color="inherit">
              close
            </Icon>
          </SuiButton>
        </SuiBox>
        <SuiBox>
          <DialogContent>
            {isLoading ? (
              <SuiBox mt={1} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size="2rem" />
              </SuiBox>
            ) : (
              <SuiBox>
                <SuiBox mt={1} display="flex">
                  <SuiTypography component="label" variant="body2">
                    จำนวนเพื่อนทั้งหมด:
                  </SuiTypography>
                  <SuiTypography ml={1} component="label" variant="body2">
                    {friend}
                  </SuiTypography>
                </SuiBox>
                <Divider />
                <SuiBox mt={1} display="flex" flexDirection="column" alignItems="center">
                  <SuiInput
                    size="small"
                    defaultValue={link}
                    value={link}
                    inputProps={{ disabled: true }}
                    disabled
                  />
                </SuiBox>
                <SuiBox mt={1} display="flex" justifyContent="flex-end">
                  <SuiButton
                    variant="gradient"
                    buttonColor="dark"
                    size="small"
                    sx={{ padding: "0.5rem 1rem" }}
                    onClick={() => handleCopyFriendRefLink()}
                  >
                    คัดลอก
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            )}
          </DialogContent>
        </SuiBox>
      </Card>
    </Dialog>
  );
}

FriendReferralPopup.defaultProps = {
  onClose: () => {},
};

FriendReferralPopup.propTypes = {
  onClose: PropTypes.func,
};

export default FriendReferralPopup;
