/* eslint-disable */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import { BottomNavigation, Paper } from "@mui/material";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import { styled } from "@mui/material/styles";
import chipcolor from "assets/png/chip-color.png";
import home from "assets/png/home.png";
import bonus from "assets/png/bonus.png";
import MemberProfilePopup from "../info/member-profile-popup";
import MONEYBAG from "../../../assets/png/money-bag2.png";
import USER from "../../../assets/png/user.png";

const API_SERVER =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_LANDIGNPAGE_POMOTION_PAGE
    : process.env.REACT_APP_LANDIGNPAGE_POMOTION_PAGE;

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: white;
  &.Mui-selected {
    color: #ff0080;
  }
`);

function BottomMenus({ page }) {
  const [value, setValue] = useState(page);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const history = useHistory();

  function handleSetValue(e, newValue) {
    // setValue(newValue);
    if (newValue === 2) {
      history.push(`/dashboard`);
    }
    if (newValue === 0) {
      history.push(`/deposit`);
    }
    if (newValue === 1) {
      history.push(`/withdraw`);
    }
    if (newValue === 3) {
      console.log("bottom-menus - API_SERVER", API_SERVER);
      if (API_SERVER !== "") {
        const newWindow = window.open("about:blank", "_blank");
        newWindow.location.href = API_SERVER;
      } else {
        history.push(`/dashboard`);
      }
    }
    if (newValue === 4) {
      setProfileOpen(true);
    }
  }

  useEffect(() => {
    if (history?.location?.pathname === "/dashboard") {
      setValue(2);
    }
    if (history?.location?.pathname === "/deposit") {
      setValue(0);
    }
    if (history?.location?.pathname === "/withdraw") {
      setValue(1);
    }
  }, [page]);

  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
      {isProfileOpen && <MemberProfilePopup onClose={() => setProfileOpen(false)} />}
      <BottomNavigation
        sx={{
          height: "75px",
          background: `linear-gradient(to bottom,  #141727 0%,#3a416f 100%)`,
        }}
        showLabels
        value={value}
        onChange={(e, newValue) => handleSetValue(e, newValue)}
      >
        <BottomNavigationAction
          label="ฝากเงิน"
          icon={
            <SuiBox
              component="img"
              src={chipcolor}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="2rem"
              height="2rem"
            />
          }
        />
        <BottomNavigationAction
          label="ถอนเงิน"
          icon={
            <SuiBox
              component="img"
              src={MONEYBAG}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="2rem"
              height="2rem"
            />
          }
        />
        <BottomNavigationAction
          label="หน้าหลัก"
          sx={{ color: "white" }}
          icon={
            <SuiBox
              mt={-5}
              mb={1}
              component="img"
              src={home}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              customClass="cursor-pointer"
            />
          }
        />

        <BottomNavigationAction
          label="โปรโมชั่น"
          icon={
            <SuiBox
              component="img"
              src={bonus}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="2rem"
              height="2rem"
            />
          }
        />
        <BottomNavigationAction
          label="โปรไฟล์"
          icon={
            <SuiBox
              component="img"
              src={USER}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="2rem"
              height="2rem"
            />
          }
        />
      </BottomNavigation>
    </Paper>
  );
}

BottomMenus.defaultProps = {
  page: 1,
};

BottomMenus.propTypes = {
  page: PropTypes.number,
};

export default BottomMenus;
