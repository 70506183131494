/* eslint-disable */
import { useState, useRef } from "react";

import PropTypes from "prop-types";

import Swal from "sweetalert2";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { ResponseAlert } from "components/ResponseAlert";
import SuiTypography from "components/SuiTypography";
import { getOTP, checkOTP } from "api/verify";
import { verifyNumber } from "utils/validations";

function VerifyPhoneNumber({ type, onSetPhoneNumber }) {
  const [phoneNumber, setPhoneNumber] = useState();
  const [countNumber, setCountNumber] = useState();
  const [otp, setOTP] = useState();
  const [error, setError] = useState(undefined);
  const [isSend, setSend] = useState(false);
  const [isResend, setResend] = useState(true);
  const [timer, setTimer] = useState("30");
  const Ref = useRef(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };

  const startTimer = (e) => {
    const { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(`${seconds}`);
    }
    if (seconds === 0) {
      setResend(true);
      setTimer("30");
    }
  };

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
    setResend(false);
  };

  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const handleGetOTP = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (phoneNumber === "") {
      setError("You must enter your name.");
      return;
    }
    const zero = phoneNumber.substring(0, 1);
    let newPhoneNumber = phoneNumber;
    if (zero === "0") {
      const num = phoneNumber.substring(1);
      newPhoneNumber = `+66${num}`;
    }
    // Swal.fire({
    //   title: "กำลังส่ง OTP ...",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    Swal.fire({
      title: "กำลังตรวจสอบ ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getOTP({
        phone_number: newPhoneNumber,
        type,
      });

      Swal.close();
      if (response?.data?.status === "success") {
        // setSend(true);
        setCountNumber(newPhoneNumber);
        onSetPhoneNumber(phoneNumber);
        // clearTimer(getDeadTime());
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      Swal.close();
      ResponseAlert(err?.response);
    }
  };

  const handleVerifyOTP = async () => {
    Swal.fire({
      title: "รอสักครู่ ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await checkOTP({ phone_number: countNumber, otp });
      Swal.close();
      if (response?.data?.status === "success") {
        onSetPhoneNumber(phoneNumber);
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      Swal.close();
      ResponseAlert(err?.response);
    }
  };

  function handleSetPhoneNumber(value) {
    console.log("VerifyPhoneNumber -> handleSetPhoneNumber - value", value);
    console.log(
      "VerifyPhoneNumber -> handleSetPhoneNumber - verifyNumber value",
      verifyNumber(value)
    );
    let pNumber = phoneNumber;
    if (verifyNumber(value) === true || value === "" || value.includes("-")) {
      pNumber = value.replace(/\D/g, "").trim();
    }
    setError(undefined);
    setPhoneNumber(pNumber);
    console.log("VerifyPhoneNumber -> handleSetPhoneNumber - pNumber", pNumber);
  }

  function handleSetOTP(value) {
    const otpnumber = value.replace(/,/g, "").trim();
    setError(undefined);
    setOTP(otpnumber);
  }

  return (
    <SuiBox>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption" textColor="white">
            กรอกเบอร์โทร (Phone number)
          </SuiTypography>

          <SuiInput
            value={phoneNumber ?? ""}
            onChange={(event) => handleSetPhoneNumber(event?.target?.value)}
            placeholder="เบอร์โทรศัพท์ (Phone number)"
          />
          {/* ไม่ต้อง verify OTP */}
          {/* {isSend && (
            <SuiBox sx={{ mt: 1 }}>
              <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="dark">
                กรอก OTP
              </SuiTypography>
              <SuiInput
                type="number"
                onChange={(event) => handleSetOTP(event?.target?.value)}
                placeholder="OTP"
              />
            </SuiBox>
          )}
          {isSend && (
            <SuiTypography variant="button" textColor="text" fontWeight="regular">
              ส่งอีกครั้ง (resend)&nbsp;
              {isResend ? (
                <SuiTypography
                  sx={{ cursor: "pointer" }}
                  onClick={handleGetOTP}
                  variant="button"
                  textColor="dark"
                  fontWeight="bold"
                  textGradient
                >
                  ส่ง (Send)
                </SuiTypography>
              ) : (
                <SuiTypography variant="button" textColor="dark" fontWeight="bold" textGradient>
                  {timer}
                </SuiTypography>
              )}
            </SuiTypography>
          )} */}
        </SuiBox>

        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".7em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox mt={4} mb={1} display="flex" justifyContent="center">
          {/* {isSend ? (
            <SuiButton onClick={handleVerifyOTP} variant="gradient" buttonColor="dark">
              ยืนยัน
            </SuiButton>
          ) : (
            <SuiButton
              // eslint-disable-next-line
              disable={phoneNumber ? false : true}
              type="submit"
              onClick={handleGetOTP}
              variant="gradient"
              buttonColor="dark"
            >
              ส่ง
            </SuiButton>
          )} */}
          <SuiButton
            // eslint-disable-next-line
            disable={phoneNumber ? false : true}
            type="submit"
            onClick={handleGetOTP}
            variant="gradient"
            buttonColor="white"
          >
            ยืนยัน
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

VerifyPhoneNumber.defaultProps = {
  type: "",
  onSetPhoneNumber: () => {},
};

// Typechecking props of the SuiAlert
VerifyPhoneNumber.propTypes = {
  type: PropTypes.string,
  onSetPhoneNumber: PropTypes.func,
};

export default VerifyPhoneNumber;
