/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { makeStyles } from "@mui/styles";

// Images
import pxToRem from "assets/theme/functions/pxToRem";

export default makeStyles(({ functions, palette, boxShadows }) => {
  const { linearGradient, rgba } = functions;
  const { gradients } = palette;
  const { xl } = boxShadows;

  console.log("style", linearGradient, rgba, gradients);
  return {
    betflixPlayCard: {
      backgroundImage: ({ image }) => image && `url(${image})`,
      backgroundSize: "cover",
      borderRadius: "lg",
      boxShadow: xl,
    },

    betflixPlayCard_number: {
      marginTop: pxToRem(24),
      marginBottom: pxToRem(40),
      paddingBottom: pxToRem(8),
    },
  };
});
