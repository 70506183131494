/* eslint-disable */
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import { Card, Grid, Icon } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import BottomMenus from "layouts/dashboards/BottomNavigation/bottom-menus";
import breakpoints from "assets/theme/base/breakpoints";
import appbarlogo from "assets/png/appbarlogo.png";
import HistoryTransactionIcon from "assets/png/history_transaction.png";
import WithdrawFrom from "./withdraw-form";
import WithdrawHistory from "./withdraw-history";

function Withdraw() {
  const history = useHistory();
  const [mobileView, setMobileView] = useState(false);
  const [isShowHistory, setShowHistory] = useState(false);

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  function handleBack() {
    history.push(`/`);
  }

  useEffect(() => {
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);

  return (
    <DashboardLayout height="100vh">
      <SuiBox display="flex" height="100vh">
        <Grid container spacing={3}>
          {!mobileView && <Grid item xs={12} lg={4} />}
          <Grid item xs={12} lg={4}>
            <SuiBox display="flex">
              <Card
                style={{
                  flex: 1,
                  backgroundColor: "#0000002e",
                  height: "100vh",
                  // marginTop: "30px",
                  // marginBottom: "30px",
                }}
              >
                <AppBar
                  position="sticky"
                  color="inherit"
                  style={{ backgroundColor: "transparent" }}
                >
                  <SuiBox display="flex" width="100%" height="60px">
                    <SuiBox
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleBack()}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      px={2}
                    >
                      <Icon className="text-white" color="inherit">
                        arrow_back_ios
                      </Icon>
                    </SuiBox>
                    <SuiBox display="flex" flex={1} justifyContent="center" alignItems="center">
                      <SuiTypography variant="h5" fontWeight="medium" textColor="white">
                        ถอนเงิน
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox
                      sx={{ cursor: "pointer" }}
                      onClick={() => setShowHistory(true)}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      px={2}
                    >
                      <SuiBox
                        component="img"
                        src={HistoryTransactionIcon}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="1.5rem"
                        height="1.5rem"
                      />
                    </SuiBox>
                  </SuiBox>
                </AppBar>
                <SuiBox display="flex" flexDirection="column" p={3}>
                  {user?.member_uid && (
                    <SuiBox>
                      <WithdrawFrom />
                    </SuiBox>
                  )}
                  {isShowHistory && <WithdrawHistory onClose={() => setShowHistory(false)} />}
                </SuiBox>
              </Card>
            </SuiBox>
          </Grid>
          {!mobileView && <Grid item xs={12} lg={4} />}
        </Grid>
      </SuiBox>
      {user?.token && <BottomMenus value={1} />}
    </DashboardLayout>
  );
}

export default Withdraw;
