import axios from "./index";

const base = "promotion";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

export const AvailableSpin = async (data) => {
  try {
    const response = await axios.post(`${base}/wheel`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const WheelSpin = async (data) => {
  try {
    const response = await axios.post(`${base}/wheelspin`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const WheelPrize = async (data) => {
  try {
    const response = await axios.post(`${base}/wheelPrize`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
