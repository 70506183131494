/* eslint-disable */
import PropTypes from "prop-types";
import { Dialog, DialogContent, Icon, Card, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import { Commission } from "api/member";
import DataTable from "examples/Tables/DataTable";
import { formatter } from "utils/validations";

const columns = [
  { Header: "วันที่", accessor: "createdAt" },
  { Header: "จำนวน", accessor: "credit" },
  { Header: "หมายเหตุ", accessor: "remark" },
];

function CommissionPopup({ onClose }) {
  const isOpen = true;
  const [commissionCashback, setCommissionCashback] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);

  async function getCommissions() {
    setLoading(true);
    try {
      const res = await Commission();
      console.log("getCommissions-res", res);
      const data = res?.data?.data;
      const commissions = data.reduce((a, b) => {
        const newB = { ...b };
        newB.credit = formatter.format(b?.credit).toString();
        if (b?.remark === "friend_cashback") {
          newB.remark = "แนะนำเพื่อน";
        }
        if (b?.remark === "cashback") {
          newB.remark = "คอมมิชชั่น";
        }

        a.push(newB);
        return a;
      }, []);
      setCommissionCashback({ columns, rows: commissions });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      ResponseAlert(e.response);
    }
  }

  useEffect(() => {
    getCommissions();
  }, []);

  console.log("CommissionPopup-commissionCashback", commissionCashback);
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0)",
          boxShadow: "none",
        },
      }}
      fullWidth
      open={isOpen}
    >
      <Card>
        <SuiBox
          p={2}
          display="flex"
          // backgroundColor="dark"
          backgroundGradient
          alignItems="center"
          justifyContent="space-between"
        >
          <SuiTypography component="label" variant="h5" textColor="dark">
            คอมมิชชั่น
          </SuiTypography>
          <SuiButton
            // variant="outlined"
            buttonColor="dark"
            size="small"
            circular
            iconOnly
            onClick={() => onClose()}
          >
            <Icon className="" color="inherit">
              close
            </Icon>
          </SuiButton>
        </SuiBox>
        <SuiBox>
          <DialogContent>
            <SuiBox>
              {isLoading ? (
                <SuiBox display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress size="1rem" />
                </SuiBox>
              ) : (
                <SuiBox>
                  {commissionCashback?.rows && commissionCashback?.rows.length > 0 ? (
                    <DataTable
                      table={commissionCashback}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                    />
                  ) : (
                    <SuiBox display="flex" flexDirection="column" alignItems="center">
                      <SuiTypography
                        component="label"
                        variant="body2"
                        flex={1}
                        textColor="secondary"
                      >
                        ท่านยังไม่มีค่าคอมมิชชั่น
                      </SuiTypography>
                      <SuiTypography
                        component="label"
                        variant="body2"
                        flex={1}
                        textColor="secondary"
                      >
                        (แนะนำเพื่อนรับคอมมิชชั่น)
                      </SuiTypography>
                    </SuiBox>
                  )}
                </SuiBox>
              )}
            </SuiBox>
          </DialogContent>

          {/* <DialogActions>
          <SuiButton variant="gradient" buttonColor="dark" onClick={() => onClose()}>
            ปิด
          </SuiButton>
        </DialogActions> */}
        </SuiBox>
      </Card>
    </Dialog>
  );
}

CommissionPopup.defaultProps = {
  onClose: () => {},
};

CommissionPopup.propTypes = {
  onClose: PropTypes.func,
};

export default CommissionPopup;
