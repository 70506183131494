/* eslint-disable react/require-default-props */
/* eslint-disable prefer-const */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import axios from "../api/index";
import { setSession } from "../api/jwt";

// const AuthContext = React.createContext(null);

// export const AuthProvider = ({ userData, children }) => {
//   let [user, setUser] = React.useState(userData);
//   let [accessToken, setAccessToken] = React.useState(`Bearer ${userData}`);
//   user = typeof user === "string" ? JSON.parse(user) : user;

//   return (
//     <AuthContext.Provider value={{ user, setUser, accessToken, setAccessToken }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// AuthProvider.propTypes = {
//   userData: PropTypes.any,
//   children: PropTypes.any,
// };

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = React.createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    // const initialize = async () => {
    //   try {
    //     const accessToken = window.localStorage.getItem("accessToken");
    //     if (accessToken && isValidToken(accessToken)) {
    //       console.log("isValidToken-accessToken", accessToken);
    //       await setSession(accessToken);
    //       axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    //       const response = await axios.post("user/accessToken");
    //       console.log("AGENT API accessToken", response);
    //       const user = response.data;
    //       dispatch({
    //         type: "INITIALIZE",
    //         payload: {
    //           isAuthenticated: true,
    //           user,
    //         },
    //       });
    //     } else {
    //       dispatch({
    //         type: "INITIALIZE",
    //         payload: {
    //           isAuthenticated: false,
    //           user: null,
    //         },
    //       });
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     dispatch({
    //       type: "INITIALIZE",
    //       payload: {
    //         isAuthenticated: false,
    //         user: null,
    //       },
    //     });
    //   }
    // };
    // initialize();
  }, []);

  const login = async (loginData) => {
    console.log("API LOGIN", loginData);
    const response = await axios.post("/authentication/login", loginData);

    console.log("API Response Login", response.data);
    if (response?.data?.status === "success") {
      const user = response?.data?.data?.user;
      console.log("API Response Login-user", user);

      setSession(user.token);
      window.localStorage.setItem("user", JSON.stringify(user));
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
      return response;
    }
    return response;
  };

  const logout = async () => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
      payload: {
        user: null,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const useAuth = () => React.useContext(AuthContext);
