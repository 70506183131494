import axios from "./index";

const base = "util";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

// export const DepositList = (data) => axios.post(`${base}/depositlist`, data);
// export const DepositConfirm = (data) => axios.post(`${base}/confirm`, data);

export const ExchangeRate = async (data) => {
  try {
    const response = await axios.post(`${base}/exchange-rate`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const Banks = async (data) => {
  try {
    const response = await axios.post(`${base}/banks`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const WheelItems = async (data) => {
  try {
    const response = await axios.post(`${base}/wheelitems`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const GETPROMOTIONLIST = async (data) => {
  try {
    const response = await axios.post(`promotion/list`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
