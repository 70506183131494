/* eslint-disable */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
// eslint-disable-next-line
import Grid from "@mui/material/Grid";
// eslint-disable-next-line
import { Card, Divider } from "@mui/material";
import { differenceInSeconds } from "date-fns";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// eslint-disable-next-line
import SuiButton from "components/SuiButton";
import { ResponseAlert } from "components/ResponseAlert";
// eslint-disable-next-line
import SuiTypography from "components/SuiTypography";

import { verifyCurrency } from "utils/validations";
import { DepositBanks, NewDeposit, UpdateDeposit, WaitingDeposit } from "api/deposit";
// eslint-disable-next-line
import AmountInput from "../credit/amount-input";
// eslint-disable-next-line
import DepositInfo from "./deposit-info";

function DepositForm({ onSetDeposit }) {
  const [deposit, setDeposit] = useState();
  const [amount, setAmount] = useState();
  // eslint-disable-next-line
  const [agBanks, setAgBanks] = useState();
  const [defaultBank, setDefaultBank] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [expiredAt, setExpiredAt] = useState();
  const history = useHistory();

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  console.log("DepositForm-amount", amount);

  async function checkWaiting() {
    try {
      const res = await WaitingDeposit();
      console.log("checkWaiting - res", res);

      console.log("checkWaiting - agBanks", agBanks);
      if (res?.data?.data?.deposit_uid) {
        const waiting = res?.data?.data;
        const newAgentBank = {
          agent_bank_name: waiting?.agent_bank_name,
          agent_bank_number: waiting?.agent_bank_number,
          agent_bank_uid: waiting?.agent_bank_uid,
          bank_code: waiting?.bank_code,
          bank_name: waiting?.bank_name,
          name: waiting?.name,
        };

        const expireTime = new Date(waiting?.expiredAt);
        const nowAt = new Date();
        console.log("DepositInfo -> getPendingOrder - expireTime", expireTime);
        console.log("DepositInfo -> getPendingOrder - nowAt", nowAt);
        const timeDiff = differenceInSeconds(expireTime, nowAt);
        console.log("DepositInfo -> getPendingOrder - timeDiff", timeDiff);
        if (timeDiff > 0) {
          setExpiredAt(timeDiff);
        }
        setDeposit(waiting);
        setDefaultBank(newAgentBank);
        setAmount(waiting?.amount);
        setActiveStep(1);
      }
    } catch (e) {
      console.log("checkWaiting-catch-err", e);
      console.log("checkWaiting-catch-err.response", e.response);
    }
  }

  async function getDepositBank() {
    try {
      const resBank = await DepositBanks();
      console.log("resBank", resBank);
      if (resBank?.data?.status === "success") {
        const agbs = resBank?.data?.data;

        const newAgentBankList = agbs.reduce((a, b) => {
          console.log("newAgentBankList-b", b);
          console.log("newAgentBankList-user", user);
          if (b?.bank_code === "999" && user?.bank_code === "999") {
            a.push(b);
          }
          if (b?.bank_code !== "999" && user?.bank_code !== "999") {
            a.push(b);
          }
          return a;
        }, []);

        setDefaultBank(newAgentBankList[0]);
        setAgBanks(newAgentBankList);
      } else {
        setDefaultBank({});
        setAgBanks([]);
      }
    } catch (err) {
      console.log("getDepositBank-catch-err", err);
      console.log("getDepositBank-catch-err.response", err.response);
    }
  }

  async function saveNewDeposit(newAmount) {
    const payload = {
      amount: newAmount,
      agent_bank_uid: defaultBank?.agent_bank_uid,
    };

    try {
      let response;
      if (deposit?.deposit_uid) {
        payload.deposit_uid = deposit?.deposit_uid;
        response = await UpdateDeposit(payload);
      } else {
        response = await NewDeposit(payload);
      }

      console.log("saveNewDeposit", response);
      if (response?.data?.status === "success") {
        setDeposit(response?.data?.data);
        onSetDeposit(response?.data?.data);
        setActiveStep(activeStep + 1);
      } else {
        ResponseAlert(response);
      }
    } catch (e) {
      console.log("saveNewDeposit-catch-e", e);
      console.log("saveNewDeposit-catch-e.response", e.response);
      ResponseAlert(e?.response);
    }
  }

  async function CancelDeposit() {
    const payload = {
      deposit_uid: deposit?.deposit_uid,
      status_code: "cancel",
    };
    try {
      const res = await UpdateDeposit(payload);
      console.log("CancelDeposit-res", res);
      setDeposit();
      setAmount();
      setExpiredAt();
      setActiveStep(0);
    } catch (e) {
      setActiveStep(0);
    }
  }

  const handleNext = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (amount && verifyCurrency(amount) && defaultBank?.agent_bank_uid) {
      if (defaultBank?.bank_code === "014") {
        const newAmount = parseFloat(amount).toFixed(2);
        setAmount(newAmount);
        saveNewDeposit(newAmount);
      } else if (defaultBank?.bank_code === "004" && user?.bank_code === "004") {
        const newAmount = parseFloat(amount).toFixed(2);
        setAmount(newAmount);
        saveNewDeposit(newAmount);
      } else if (
        amount.includes(".") &&
        defaultBank?.bank_code !== "004" &&
        defaultBank?.bank_code !== "014" &&
        user?.bank_code !== "004"
      ) {
        const amountSplit = amount.split(".");
        const dg = amountSplit[1];
        if (dg === "0" || dg === "00") {
          const decimal = Math.floor(Math.random() * 100);
          const newAmount = `${amountSplit[0]}.${decimal}`;
          setAmount(newAmount);
          saveNewDeposit(newAmount);
        } else {
          const newAmount = parseFloat(amount).toFixed(2);
          setAmount(newAmount);
          saveNewDeposit(newAmount);
        }
      } else {
        const decimal = Math.floor(Math.random() * 50 + 10);
        const newAmount = `${amount}.${decimal}`;
        setAmount(newAmount);
        saveNewDeposit(newAmount);
      }
    }
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleConfirm = () => {
    console.log("handleConfirm");
    history.push(`/dashboard`);
  };

  const handleSetAmount = (value) => {
    if (value <= 0) return;
    setAmount(value);
  };

  useEffect(() => {
    getDepositBank();
    checkWaiting();
  }, []);

  return (
    <SuiBox px={2} lineHeight={1.25} textAlign="center">
      <Card sx={{ p: 2 }}>
        <SuiBox display="flex" flexDirection="column">
          <SuiTypography variant="h5" fontWeight="medium">
            ฝากเงิน
          </SuiTypography>
        </SuiBox>
        <Divider />
        <SuiBox mt={1} component="form" role="form">
          {activeStep === 0 && (
            <AmountInput
              amount={amount}
              onSetAmount={(v) => handleSetAmount(v)}
              agBanks={agBanks}
              defaultBank={defaultBank}
              onSetAgBank={(b) => setDefaultBank(b)}
              onNextStep={() => handleNext()}
            />
          )}
          {amount && activeStep === 1 && (
            <DepositInfo
              deposit={deposit}
              amount={amount}
              defaultBank={defaultBank}
              expiredAt={expiredAt}
              onCancleDeposit={() => checkWaiting()}
            />
          )}
          <SuiBox mt={3} width="100%" display="flex" justifyContent="space-between">
            {activeStep === 0 ? (
              <SuiBox />
            ) : (
              <SuiButton variant="gradient" buttonColor="light" onClick={handleBack}>
                แก้ไข
              </SuiButton>
            )}

            <SuiButton
              type="submit"
              variant="gradient"
              buttonColor="dark"
              onClick={(event) => {
                if (activeStep === 0) {
                  handleNext(event);
                } else {
                  handleConfirm(event);
                }
              }}
            >
              {activeStep === 0 ? "ยืนยัน" : "โอนเงินแล้ว"}
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </Card>
    </SuiBox>
  );
}

DepositForm.defaultProps = {
  onSetDeposit: () => {},
};

DepositForm.propTypes = {
  onSetDeposit: PropTypes.func,
};

export default DepositForm;
