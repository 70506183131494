import { useState, useEffect } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DefaultItem from "examples/Items/DefaultItem";
import styles from "examples/Navbars/DashboardNavbar/styles";
import { useAuth } from "auth-context/auth.context";
import { useSoftUIController } from "context";
import appbarlogo from "assets/png/appbarlogo.png";

// eslint-disable-next-line
function DashboardNavbar({ absolute, light, isMini, onClickBack, onClickFriendRef }) {
  // eslint-disable-next-line
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  // eslint-disable-next-line
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  // eslint-disable-next-line
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    setNavbarType("sticky");

    function handleTransparentNavbar() {
      dispatch({
        type: "TRANSPARENT_NAVBAR",
        value: (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      });
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const { logout } = useAuth();
  const history = useHistory();

  function handleFriendReferral() {
    handleCloseMenu();
    onClickFriendRef();
  }

  async function handleLogout() {
    handleCloseMenu();
    try {
      await logout();
      history.push(`/sign-in`);
    } catch (err) {
      console.log("handleLogout-catch-err", err?.response);
    }
  }

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ marginTop: "1rem" }}
    >
      <SuiBox pr={2} sx={{ cursor: "pointer" }} onClick={() => handleFriendReferral()}>
        <DefaultItem icon="person" title="แนะนำเพื่อน" />
      </SuiBox>
      <SuiBox pr={2} sx={{ cursor: "pointer" }} onClick={() => handleLogout()}>
        <DefaultItem icon="logout" title="ออกจากระบบ" />
      </SuiBox>
    </Menu>
  );

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  return (
    <AppBar position="sticky" className={classes.navbar}>
      <Toolbar>
        {isMini ? null : (
          <SuiBox customClass={classes.navbar_row}>
            <SuiBox display="flex" justifyContent="center" alignItems="center">
              {history?.location?.pathname !== "/dashboard" && (
                <SuiBox width={40} alignItems="center">
                  <IconButton size="medium" color="inherit" onClick={() => onClickBack()}>
                    <Icon className="text-white">arrow_back</Icon>
                  </IconButton>
                </SuiBox>
              )}
              <SuiBox component={Link} to="/dashboard" py={0.75} lineHeight={1}>
                <SuiBox ml={2} component="img" src={appbarlogo} alt="pattern-lines" height="60px" />
              </SuiBox>
            </SuiBox>

            <SuiBox
              display="flex"
              color={light ? "white" : "inherit"}
              justifyContent="flex-end"
              alignItems="center"
              width="calc(100%)"
            >
              <SuiBox display="flex" flexDirection="column">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textColor="white"
                >
                  {user?.phone_number}
                </SuiTypography>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textColor="white"
                >
                  {user?.username ? `ID: ${user?.username}` : ""}
                </SuiTypography>
              </SuiBox>

              <IconButton
                size="medium"
                color="inherit"
                className={classes.navbar_icon_button}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon className={!light ? "text-white" : "text-dark"} fontSize="default">
                  {openMenu ? "close" : "menu"}
                </Icon>
              </IconButton>
              {renderMenu()}
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  onClickBack: () => {},
  onClickFriendRef: () => {},
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  onClickBack: PropTypes.func,
  onClickFriendRef: PropTypes.func,
};

export default DashboardNavbar;
