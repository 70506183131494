/* eslint-disable */
import { useState, useEffect } from "react";
import { Card, CircularProgress, Dialog, Grid, Icon } from "@mui/material";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import { ResponseAlert } from "components/ResponseAlert";
import DataTable from "examples/Tables/DataTable";
import { depositTransaction } from "api/member";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { format } from "date-fns";

const columns = [
  { Header: "วันที่/เวลา", accessor: "createdAt" },
  { Header: "จำนวน(บาท)", accessor: "amount" },
  { Header: "สถานะ", accessor: "status_code" },
];

function DepositHistory({ onClose }) {
  const [depositTableData, setDepositTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const isOpen = true;

  async function getDepositList() {
    setLoading(true);
    try {
      const response = await depositTransaction();
      console.log("getDepositList-response", response);
      setLoading(false);
      const status = response?.data?.status;
      if (status === "success") {
        const depoList = response.data?.data ?? [];
        const newDepositList = depoList.reduce((a, b) => {
          const newB = { ...b };
          if (!b?.manual_by) {
            let statusCode = "";
            let statusColor = "";
            if (b?.status_code === "success") {
              statusCode = "สำเร็จ";
              statusColor = "success";
            }
            if (b?.status_code === "waiting") {
              statusCode = "รอทำรายการ";
              statusColor = "secondary";
            }
            if (b?.status_code === "cancel") {
              statusCode = "ยกเลิก";
              statusColor = "error";
            }
            newB.createdAt = format(new Date(b?.updatedAt), "dd/MM HH:mm");
            newB.status_code = (
              <SuiBox display="flex" justifyContent="center" alignItems="center">
                <SuiBox position="absolute" customClass="object-center object-center" zIndex={0}>
                  <SuiBadge
                    variant="contained"
                    color={statusColor}
                    badgeContent={statusCode}
                    size="extra-small"
                    container
                    circular
                  />
                </SuiBox>
              </SuiBox>
            );
          }
          a.push(newB);
          return a;
        }, []);
        setDepositTableData({ columns, rows: newDepositList });
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      setLoading(false);
      ResponseAlert(err.response);
    }
  }

  useEffect(() => {
    getDepositList();
  }, []);

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "rgba(255, 255, 255, 0)",
          boxShadow: "none",
        },
      }}
      fullWidth
      open={isOpen}
    >
      <Card>
        <SuiBox
          p={2}
          display="flex"
          // backgroundColor="dark"
          backgroundGradient
          alignItems="center"
          justifyContent="space-between"
        >
          <SuiTypography component="label" variant="h5" textColor="dark">
            ประวัติการฝาก
          </SuiTypography>

          <SuiButton
            // variant="outlined"
            buttonColor="dark"
            size="small"
            circular
            iconOnly
            onClick={() => onClose()}
          >
            <Icon className="" color="inherit">
              close
            </Icon>
          </SuiButton>
        </SuiBox>
        <SuiBox px={2} lineHeight={1.25} textAlign="center">
          <Grid item xs={12}>
            <Card>
              {isLoading ? (
                <SuiBox
                  p={3}
                  lineHeight={0}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="secondary" />
                </SuiBox>
              ) : (
                <SuiBox p={2}>
                  <DataTable
                    table={depositTableData}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                    noEndBorder
                  />
                </SuiBox>
              )}
            </Card>
          </Grid>
        </SuiBox>
      </Card>
    </Dialog>
  );
}

export default DepositHistory;
