/* eslint-disable */
import { useState } from "react";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { Dialog, DialogContent, Divider, Icon, IconButton } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ResponseAlert } from "components/ResponseAlert";
import { MemberChangePassword } from "api/member";
import SuiSnackbar from "components/SuiSnackbar";
import SuiAlert from "components/SuiAlert";
import Swal from "sweetalert2";
import DotLoader from "react-spinners/DotLoader";

function ChangePassword({ onCancel }) {
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [error, setError] = useState({});
  const [isShow, setShowPassword] = useState(false);
  const [isShowConfirm, setShowConfirm] = useState(false);
  const [isShowOldPassword, setShowOldPassword] = useState(false);
  const [response, setResponse] = useState();
  const [isSnackbar, setSnackbar] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [isLoading, setLoading] = useState(false);

  async function handleCheckPassword() {
    var regularExpression = /^[a-z0-9_.,'"!?;:& ]+$/;
    const newError = { ...error };

    if (!oldPassword) {
      newError.oldPassword = "รหัสผ่านห้ามเว้นว่าง";
      setError(newError);
      return;
    }
    if (!newPassword) {
      newError.newPassword = "รหัสผ่านห้ามเว้นว่าง";
      setError(newError);
      return;
    }
    if (!confirmNewPassword) {
      newError.confirmNewPassword = "รหัสผ่านห้ามเว้นว่าง";
      setError(newError);
      return;
    }
    if (!regularExpression.test(newPassword)) {
      newError.newPassword = "รหัสผ่านต้องเป็นภาษาอังกฤษ และ/หรือ ตัวเลข เท่านั้น";
      setError(newError);
      return;
    }
    if (!regularExpression.test(confirmNewPassword)) {
      newError.confirmNewPassword = "รหัสผ่านต้องเป็นภาษาอังกฤษ และ/หรือ ตัวเลข เท่านั้น";
      setError(newError);
      return;
    }
    if (newPassword !== confirmNewPassword) {
      newError.confirmNewPassword = "รหัสผ่านไม่ตรงกัน";
      setError(newError);
      return;
    }
    if (newPassword.length < 6 || newPassword.length > 16) {
      newError.newPassword = "รหัสผ่านต้องมี 6-16 ตัวอักษร";
      setError(newError);
      return;
    }
    if (confirmNewPassword.length < 6 || confirmNewPassword.length > 16) {
      newError.confirmNewPassword = "รหัสผ่านต้องมี 6-16 ตัวอักษร";
      setError(newError);
      return;
    }
    setDisable(true);
    setLoading(true);
    const data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };
    try {
      const res = await MemberChangePassword(data);
      setLoading(false);
      const status = res?.data?.status;
      if (status === "success") {
        const sb = {
          type: "success",
          title: "สำเร็จ",
          icon: "check",
          content: "เปลี่ยนรหัสผ่านเรียบร้อย",
        };
        setSnackbar(true);
        setResponse(sb);
        onCancel();
      } else {
        ResponseAlert(res);
      }
    } catch (err) {
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  function handleChangeOldPassword(value) {
    setOldPassword(value);
    const newError = { ...error };
    if (value) {
      delete newError.oldPassword;
      setError(newError);
    }
  }

  function handleChangeNewPassword(value) {
    setNewPassword(value);
    const newError = { ...error };
    if (value) {
      delete newError.newPassword;
      setError(newError);
    }
  }

  function handleChangeConfirmNewPassword(value) {
    setConfirmNewPassword(value);
    const newError = { ...error };
    if (value) {
      delete newError.confirmNewPassword;
      setError(newError);
    }
  }

  const closeSnackbar = () => {
    setResponse();
    setOldPassword();
    setNewPassword();
    setConfirmNewPassword();
    setSnackbar(false);
    setDisable(false);
  };

  return (
    <SuiBox>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0)",
            boxShadow: "none",
          },
        }}
        // fullWidth
        open={isLoading}
      >
        <DialogContent>
          <SuiBox display="flex" justifyContent="center">
            <DotLoader color="#ffffff" />
          </SuiBox>
        </DialogContent>
      </Dialog>

      <SuiSnackbar
        type={response?.type}
        icon={response?.icon}
        title={response?.title}
        content={response?.content}
        open={isSnackbar}
        onClose={() => closeSnackbar()}
        close={() => closeSnackbar()}
      />
      {/* {renderSnackbar} */}
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiBox>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              รหัสผ่านปัจจุบัน *
            </SuiTypography>
            <SuiBox display="flex">
              <SuiInput
                type={isShowOldPassword ? "text" : "password"}
                onChange={(event) => {
                  handleChangeOldPassword(event.target.value);
                }}
                inputProps={{ disabled: isDisable }}
                placeholder="รหัสผ่านปัจจุบัน"
                error={error?.oldPassword}
              />
              <IconButton
                sx={{ ml: 1 }}
                size="medium"
                color="inherit"
                onClick={() => setShowOldPassword(!isShowOldPassword)}
                disabled={isDisable}
              >
                <Icon className="text-dark">
                  {isShowOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Icon>
              </IconButton>
            </SuiBox>
            <SuiBox>
              <SuiTypography
                component="div"
                variant="caption"
                textColor={error?.oldPassword ? "error" : "transparent"}
              >
                {error?.oldPassword ?? "-"}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <Divider />

          <SuiBox>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              รหัสผ่านใหม่ *
            </SuiTypography>
            <SuiBox display="flex">
              <SuiInput
                type={isShow ? "text" : "password"}
                onChange={(event) => {
                  handleChangeNewPassword(event.target.value);
                }}
                inputProps={{ disabled: isDisable }}
                placeholder="รหัสผ่านใหม่"
                error={error?.newPassword}
              />
              <IconButton
                sx={{ ml: 1 }}
                size="medium"
                color="inherit"
                onClick={() => setShowPassword(!isShow)}
                disabled={isDisable}
              >
                <Icon className="text-dark">
                  {isShow ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Icon>
              </IconButton>
            </SuiBox>
            <SuiBox>
              <SuiTypography
                component="div"
                variant="caption"
                textColor={error?.newPassword ? "error" : "transparent"}
              >
                {error?.newPassword ?? "-"}
              </SuiTypography>
            </SuiBox>
          </SuiBox>

          <SuiBox sx={{ mt: 1 }}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              ยืนยันรหัสผ่านใหม่ *
            </SuiTypography>
            <SuiBox display="flex">
              <SuiInput
                type={isShowConfirm ? "text" : "password"}
                onChange={(event) => {
                  handleChangeConfirmNewPassword(event.target.value);
                }}
                inputProps={{ disabled: isDisable }}
                placeholder="ยืนยันรหัสผ่านใหม่"
                error={error?.confirmNewPassword}
              />
              <IconButton
                sx={{ ml: 1 }}
                size="medium"
                color="inherit"
                onClick={() => setShowConfirm(!isShowConfirm)}
                disabled={isDisable}
              >
                <Icon className="text-dark">
                  {isShowConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Icon>
              </IconButton>
            </SuiBox>
            <SuiBox>
              <SuiTypography
                component="div"
                variant="caption"
                textColor={error?.confirmNewPassword ? "error" : "transparent"}
              >
                {error?.confirmNewPassword ?? "-"}
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>

        <SuiBox mt={4} mb={1} display="flex" justifyContent="space-between">
          <SuiButton
            onClick={() => onCancel()}
            variant="gradient"
            buttonColor="secondary"
            inputProps={{ disabled: isDisable }}
            circular
          >
            ยกเลิก
          </SuiButton>
          <SuiButton
            onClick={() => handleCheckPassword()}
            variant="gradient"
            buttonColor="dark"
            inputProps={{ disabled: isDisable }}
            circular
          >
            ยืนยัน
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

ChangePassword.defaultProps = {
  onCancel: () => {},
};

ChangePassword.propTypes = {
  onCancel: PropTypes.func,
};

export default ChangePassword;
