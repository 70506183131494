/* eslint-disable */
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SuiBox from "components/SuiBox";
import breakpoints from "assets/theme/base/breakpoints";
import { Wheel } from "react-custom-roulette";
import wheelArrow from "../../assets/png/wheelArrow.png";
import wheelframe from "../../assets/png/wheelframe.png";
import wheelouter from "../../assets/gif/wheel-outer.gif";
import spinbutton from "../../assets/gif/spin-button.gif";
import wheelbase from "../../assets/png/wheelbase.png";
import pointlabel from "assets/png/pointlabel.png";
import SuiTypography from "components/SuiTypography";
import * as CryptoJS from "crypto-js";
import { AvailableSpin, WheelSpin } from "api/promotion";
import { Card, CircularProgress, Dialog, DialogContent, Icon } from "@mui/material";
import { WheelPrize } from "api/promotion";
import Confetti from "react-confetti";
import DotLoader from "react-spinners/DotLoader";
import SuiButton from "components/SuiButton";
import SuiProgress from "components/SuiProgress";
import { ResponseAlert } from "components/ResponseAlert";

const prizes = [
  { value: "5", label: "5", pct: "40" },
  { value: "10", label: "10", pct: "15" },
  { value: "50", label: "50", pct: "10" },
  { value: "5", label: "5", pct: "40" },
  { value: "20", label: "20", pct: "30" },
  { value: "100", label: "100", pct: "2" },
  { value: "500", label: "500", pct: "1" },
  { value: "5", label: "5", pct: "40" },
  { value: "10", label: "10", pct: "15" },
  { value: "200", label: "200", pct: "1" },
  { value: "5", label: "5", pct: "40" },
  { value: "1000", label: "1,000", pct: "1" },
];

const data = [
  { option: "5", style: { backgroundColor: "#3e0030", textColor: "#f5a32e" } },
  { option: "10", style: { backgroundColor: "#f5a32e", textColor: "#3e0030" } },
  { option: "50", style: { backgroundColor: "#3e0030", textColor: "#f5a32e" } },
  { option: "5", style: { backgroundColor: "#f5a32e", textColor: "#3e0030" } },
  { option: "20", style: { backgroundColor: "#3e0030", textColor: "#f5a32e" } },
  { option: "100", style: { backgroundColor: "#f5a32e", textColor: "#3e0030" } },
  { option: "500", style: { backgroundColor: "#3e0030", textColor: "#f5a32e" } },
  { option: "5", style: { backgroundColor: "#f5a32e", textColor: "#3e0030" } },
  { option: "10", style: { backgroundColor: "#3e0030", textColor: "#f5a32e" } },
  { option: "200", style: { backgroundColor: "#f5a32e", textColor: "#3e0030" } },
  { option: "5", style: { backgroundColor: "#3e0030", textColor: "#f5a32e" } },
  { option: "1,000", style: { backgroundColor: "#f5a32e", textColor: "#3e0030" } },
];

function WheelComponent() {
  const history = useHistory();
  const [eligible, setEligible] = useState(false);
  const [availableSpin, setAvailableSpin] = useState(0);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState();
  const [mobileView, setMobileView] = useState(false);
  const [isLoadSpin, setLoadSpin] = useState(false);
  const [wheelSpinId, setSpinId] = useState(false);
  const [isFinished, setFinish] = useState(false);
  const [buttondisabled, setbuttondisable] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCondition, setCodition] = useState(true);
  const [percentPoint, setPercentPoint] = useState(0);
  const [point, setPoint] = useState(0);
  const [score, setScore] = useState(0);

  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;

  const getWheel = async () => {
    setLoadSpin(true);

    try {
      const response = await AvailableSpin();
      setLoadSpin(false);
      console.log("wheelComponent-getWheelItems-AvailableSpin", response?.data);
      if (response?.data?.status === "success") {
        const data = response?.data?.data;
        setAvailableSpin(data?.available);
        if (data?.pending_spinId) {
          console.log("wheelComponent-getWheelItems-pending_spinId", data?.pending_spinId);
          const decryptPrize = CryptoJS.AES.decrypt(
            data?.pending_spinId,
            "spacialpromotionwheel"
          ).toString(CryptoJS.enc.Utf8);
          const spinPrize = JSON.parse(decryptPrize);
          const prizeValue = spinPrize?.value;
          const winnerPrize = prizes.find((f) => f.value === prizeValue);
          localStorage.setItem("isSpin", 1);
          setFinish(true);
          Swal.fire({
            title: "ยินดีด้วยค่ะ",
            icon: "success",
            html: `คุณได้รับโบนัสจำนวน <b>${winnerPrize?.label}</b> เครดิต`,
            allowOutsideClick: false,
            preConfirm: () => {
              setFinish(false);
              wheelGetPrize(data?.pending_spinId);
            },
          });
        }
        if (data?.point) {
          const memberPoint = data?.point;
          console.log("wheelComponent-getWheelItems-memberPoint", memberPoint);

          if (memberPoint.length > 6) {
            const hValue = memberPoint.substr(memberPoint.length - 6);
            console.log(
              "wheelComponent-getWheelItems-memberPoint.6 - hValue",
              hValue,
              parseFloat(hValue)
            );
            setScore(parseFloat(hValue));
            const result = (parseFloat(hValue) * 100) / 1000;
            console.log("wheelComponent-getWheelItems-result", result);
            setPercentPoint(result);
          } else {
            const result = (parseFloat(memberPoint) * 100) / 1000;
            console.log("wheelComponent-getWheelItems-result", result, parseFloat(memberPoint));
            setPercentPoint(result);

            setScore(parseFloat(memberPoint));
          }

          setPoint(parseFloat(memberPoint));
        }
        if (data?.eligible) {
          winResult();
        }
        setEligible(data?.eligible);
      }
    } catch (err) {
      setLoadSpin(false);
      ResponseAlert(err?.response);
      console.log("err", err);
    }
  };

  const wheelStartSpin = async () => {
    const winnerPrize = prizes[prizeNumber].value;
    const spinTimeStamp = new Date().getTime();
    const spinId = CryptoJS.AES.encrypt(
      `{"timeStamp":"${spinTimeStamp}","value":"${winnerPrize}"}`,
      "spacialpromotionwheel"
    ).toString();
    setSpinId(spinId);
    const payload = {
      spinId: spinId,
    };
    try {
      const res = await WheelSpin(payload);
      const data = res?.data?.data;
      if (availableSpin > 0) {
        if (data?.eligible) {
          winResult();
        }
        setEligible(data?.eligible);
        setAvailableSpin(data?.available);
      }
    } catch (e) {
      ResponseAlert(err?.response);
    }
  };

  const wheelGetPrize = async (sid) => {
    const payload = {
      spinId: sid ? sid : wheelSpinId,
    };
    setLoading(true);
    try {
      const res = await WheelPrize(payload);

      setLoading(false);
      const data = res?.data?.data;
      setSpinId();
      if (data?.available > 0) {
        localStorage.removeItem("isSpin");
        getWheel();
      }
    } catch (e) {
      setLoading(false);
      ResponseAlert(err?.response);
    }
  };

  const handleSpinClick = () => {
    console.log("wheelComponent-handleSpinClick", localStorage.getItem("isSpin"));

    if (!mustSpin) {
      localStorage.setItem("isSpin", 1);
      const newAvailableSpin = availableSpin - 1;
      setAvailableSpin(newAvailableSpin > 0 ? newAvailableSpin : 0);
      setEligible(false);
      wheelStartSpin();
      setMustSpin(true);
    }
  };

  const handleStopSpin = () => {
    setMustSpin(false);
    setPrizeNumber();
    setEligible(false);
    setFinish(true);
    const winnerPrize = prizes[prizeNumber].label;
    wheelGetPrize();
    Swal.fire({
      title: "ยินดีด้วยค่ะ",
      icon: "success",
      html: `คุณได้รับโบนัสจำนวน <b>${winnerPrize}</b> เครดิต`,
      allowOutsideClick: false,
      preConfirm: () => {
        setFinish(false);
        Swal.close();
      },
    });
  };

  const winResult = () => {
    const expanded = prizes.flatMap((item) => Array(Number(item.pct)).fill(item));
    const winner = expanded[Math.floor(Math.random() * expanded.length)];
    const winSag = winner?.value;
    console.log("wheelComponent-winSag", winSag);

    const prizeIndexs = prizes.reduce(function (a, e, i) {
      if (e.value === winSag) a.push(i);
      return a;
    }, []);
    console.log("wheelComponent-prizeIndexs", prizeIndexs);
    const prizeIndex = prizeIndexs[Math.floor(Math.random() * prizeIndexs.length)];
    console.log("wheelComponent-winResult", prizeIndex);
    setPrizeNumber(prizeIndex);
  };

  const onFocus = () => {
    if (localStorage.getItem("isSpin")) {
      setEligible(false);
    }
  };

  const onBlur = () => {
    console.log("wheelComponent-onBlur", "onBlur");
  };

  const conditonSpin = () => {
    Swal.fire({
      title: "เงื่อนไขการหมุนกงล้อ",
      html: `ทุกยอดฝากจะถูกสะสมเป็นพ้อยท์ เพื่อรับสิทธิ์ในการหมุนกงล้อ<br/><b>1 บาท</b> = <b>1 พ้อยท์</b><br/><b>1,000 พ้อยท์</b> = <b>1 สิทธิ์</b>`,
      allowOutsideClick: false,
      preConfirm: () => {
        Swal.close();
      },
    });
  };

  useEffect(() => {
    getWheel();

    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }

    const handleTabClose = (event) => {
      event.preventDefault();

      // return (event.returnValue = "Are you sure you want to exit?");
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    window.addEventListener("beforeunload", handleTabClose);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  return (
    <SuiBox p={3}>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0)",
            boxShadow: "none",
          },
        }}
        fullWidth
        open={isCondition}
      >
        <Card>
          <SuiBox
            p={2}
            display="flex"
            // backgroundColor="dark"
            backgroundGradient
            alignItems="center"
            justifyContent="space-between"
          >
            <SuiTypography component="label" variant="h5" textColor="dark">
              เงื่อนไขการหมุนกงล้อ
            </SuiTypography>

            <SuiButton
              // variant="outlined"
              buttonColor="dark"
              size="small"
              circular
              iconOnly
              onClick={() => setCodition(false)}
            >
              <Icon className="" color="inherit">
                close
              </Icon>
            </SuiButton>
          </SuiBox>
          <SuiBox display="flex">
            <DialogContent>
              <SuiBox display="flex" flexDirection="column" alignItems="center">
                <SuiTypography component="label" variant="caption" textColor="secondary" flex={1}>
                  ทุกยอดฝากจะถูกสะสมเป็นพ้อยท์ เพื่อรับสิทธิ์ในการหมุนกงล้อ
                </SuiTypography>
                <SuiBox display="flex" justifyContent="center">
                  <SuiTypography component="label" variant="body2" flex={2}>
                    1 บาท = 1 พ้อยท์
                  </SuiTypography>
                </SuiBox>
                <SuiBox display="flex" justifyContent="center">
                  <SuiTypography component="label" variant="body2" flex={2}>
                    1,000 พ้อยท์ = 1 สิทธิ์
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
            </DialogContent>
          </SuiBox>
        </Card>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0)",
            boxShadow: "none",
          },
        }}
        // fullWidth
        open={isLoading}
      >
        <DialogContent>
          <SuiBox display="flex" justifyContent="center">
            <DotLoader color="#ffffff" />
          </SuiBox>
        </DialogContent>
      </Dialog>
      <SuiBox display="flex" flexDirection="column" px={2}>
        <SuiBox display="flex" justifyContent="space-between">
          <SuiTypography component="label" fontWeight="bold" textColor="white">
            Point: {point}
          </SuiTypography>
          <SuiTypography component="label" fontWeight="bold" textColor="white">
            {score} / 1,000
          </SuiTypography>
        </SuiBox>
        <SuiBox mt={1} backgroundColor="dark" width="100%" height="20px" borderRadius="lg">
          <SuiBox
            backgroundColor="info"
            width={`${percentPoint}%`}
            height="20px"
            borderRadius="lg"
            backgroundGradient="info"
          >
            {/* <SuiProgress value={percentPoint} color="info" gradient noLabel /> */}
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <SuiBox display="flex" justifyContent="center" alignItems="center" mt={10}>
        <SuiBox display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <SuiBox display="flex" justifyContent="center" alignItems="center">
            <SuiBox
              width={mobileView ? "94vw" : "520px"}
              component="img"
              src={wheelframe}
              alt="wheelframe"
              // zIndex={99}
              position="absolute"
            />
            <SuiBox sx={{ transform: "rotate(-45deg)" }}>
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                backgroundColors={["#3e0030", "#f5a32e"]}
                perpendicularText={true}
                outerBorderColor="#c38d33"
                radiusLineWidth={0}
                spinDuration={0.2}
                pointerProps={{ src: wheelArrow }}
                onStopSpinning={() => {
                  handleStopSpin();
                }}
                fontFamily="Kanit"
                fontSize={24}
                fontWeight="bold"
                textDistance={70}
              />
            </SuiBox>
            <SuiBox
              sx={{ transform: "rotate(-30deg)" }}
              width={mobileView ? "110vw" : "550px"}
              component="img"
              src={wheelouter}
              alt="wheelouter"
              // zIndex={99}
              position="absolute"
            />

            <SuiBox
              width={mobileView ? "20vw" : "100px"}
              component="img"
              src={spinbutton}
              alt="spinbutton"
              zIndex={99}
              position="absolute"
              sx={{
                cursor:
                  eligible && prizeNumber !== undefined
                    ? "pointer"
                    : isLoadSpin
                    ? "wait"
                    : "not-allowed",
              }}
              onClick={
                eligible === true && prizeNumber !== undefined && availableSpin > 0
                  ? () => handleSpinClick()
                  : () => {}
              }
            />
          </SuiBox>
          <SuiBox display="flex" flexDirection="column">
            <SuiBox
              width={mobileView ? "100vw" : "550px"}
              component="img"
              src={wheelbase}
              alt="wheelbase"
            />
            <SuiBox
              width={mobileView ? "100vw" : "550px"}
              mt={mobileView ? "21vw" : "115px"}
              display="flex"
              position="absolute"
              // zIndex={99}
              justifyContent="center"
              sx={{ cursor: "pointer" }}
            >
              <SuiTypography
                component="label"
                variant={mobileView ? "h5" : "h3"}
                fontWeight="bold"
                textColor="white"
              >
                คุณมี {isLoadSpin ? <CircularProgress size="1.5rem" color="info" /> : availableSpin}{" "}
                สปินที่ใช้ได้
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          {isFinished && (
            <SuiBox display="flex" justifyContent="center" alignItems="center">
              <Confetti />
            </SuiBox>
          )}
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default WheelComponent;
