import { useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { Dialog, DialogContent, Icon } from "@mui/material";
// import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import { resetPassword } from "api/verify";
import VerifyPhoneNumber from "../sign-up/basic/varify-phone-number";
import SetPassword from "../sign-up/basic/set-password";

function ForgotPasswordPopup({ onClose }) {
  const [activeStep, setActiveStep] = useState(0);
  const [phonenumber, setPhoneNumber] = useState();
  const isOpen = true;

  async function handleReSetPassword(value) {
    if (!value) return;
    Swal.fire({
      title: "กำลังรีเซ็ตรหัสผ่าน ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const payload = { phone_number: phonenumber, newPassword: value };
    try {
      const response = await resetPassword(payload);
      Swal.close();
      if (response?.data?.status === "success") {
        Swal.fire({
          title: "รีเซ็ตรหัสผ่านสำเร็จ",
          text: "รหัสสำหรับเข้าใช้งาน",
          icon: "success",
          html: `ยูสเซอร์: <b>${phonenumber}</b><br/>รหัส: <b>${value}</b>`,
          showCancelButton: false,
          confirmButtonText: "ปิด",
          reverseButtons: true,
          allowOutsideClick: false,
          preConfirm: () => {
            setPhoneNumber();
            onClose();
          },
        });
      }
    } catch (error) {
      Swal.close();
      ResponseAlert(error?.response);
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <VerifyPhoneNumber
            type="reset_password"
            onSetPhoneNumber={(pn) => {
              setPhoneNumber(pn);
              setActiveStep(activeStep + 1);
            }}
          />
        );
      case 1:
        return (
          <SetPassword
            onSetPassword={(value) => {
              handleReSetPassword(value);
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Dialog fullWidth open={isOpen}>
      <SuiBox
        p={2}
        display="flex"
        backgroundColor="dark"
        backgroundGradient
        justifyContent="space-between"
        alignItems="center"
      >
        <SuiTypography component="label" variant="h6" fontWeight="bold" textColor="white">
          รีเซ็ตรหัสผ่าน
        </SuiTypography>
        <SuiBox onClick={() => onClose()}>
          <Icon fontSize="default" className="text-white">
            close
          </Icon>
        </SuiBox>
      </SuiBox>
      <SuiBox>
        <DialogContent>{getStepContent(activeStep)}</DialogContent>
      </SuiBox>
    </Dialog>
  );
}

ForgotPasswordPopup.defaultProps = {
  onClose: () => {},
};

ForgotPasswordPopup.propTypes = {
  onClose: PropTypes.func,
};

export default ForgotPasswordPopup;
