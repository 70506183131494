import axios from "./index";

const base = "tranfer";
const accessToken = window.localStorage.getItem("accessToken");
axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

// export const MemberList = (data) => axios.post(`${base}/memberlist`, data);
// export const FindMember = (data) => axios.post(`${base}/findmember`, data);

export const BetflixTranfer = async (data) => {
  try {
    const response = await axios.post(`${base}/betflix`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const UfaTranfer = async (data) => {
  try {
    const response = await axios.post(`${base}/ufa`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const LotteryTranfer = async (data) => {
  try {
    const response = await axios.post(`${base}/lottery`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const EligibleTranfer = async (data) => {
  try {
    const response = await axios.post(`${base}/eligible`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const TranferAll = async (data) => {
  try {
    const response = await axios.post(`${base}/all`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};
